import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import React, {useEffect, useState} from "react";
import style from "./HomepageMap.module.css";
import {frontendURL, JobAPI} from "../../lib/ladder";

import classNames from 'classnames'

export default function HomepageMap({containerId = 'map'}) {
    let zoom = 3.5;
    let map;

    useEffect(  () => {
        mapboxgl.accessToken = 'pk.eyJ1IjoibWVldGxhZGRlciIsImEiOiJja3dsNTR2ZmExeXlqMnVwYTRqazZzbjZ5In0.k7lXAMyuoOAwK1gXETStRw';
        if (typeof window !== 'undefined') {
            if (window.screen.width <= 768) {
                zoom = 2.5
            }
        }

        JobAPI.allForHomepage().then((data) => {
            map = new mapboxgl.Map({
                container: containerId,
                style: 'mapbox://styles/meetladder/cl67ik90q000b14nz4st2xhy5',
                center: [-93, 39],
                zoom: zoom,
            });

            map.on('style.load', () => {
                map.setFog({});
            });

            data.jobs.forEach(job => {
                let marker = new mapboxgl.Marker({
                    color: "#de6d43",
                    draggable: false
                }).setLngLat([
                                Number(job.longitude),
                                Number(job.latitude)
                            ]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(
                                    '<h6>' + job.location + '</h6>' +
                                    '<h6>' + job.location_jobs.length + ' Jobs</h6>' +
                                    "<a role='button' href='/jobs?page=1&location=" + job.location.replaceAll(',', '').replaceAll(' ', '-').toLowerCase()
                    + "' class='btn btn-primary'> View Jobs</a>"
                                   )).addTo(map);
            });
        });
    }, [])

    const classList = classNames([style['mapBox']])

    return <>
        <div id={containerId} className={classList}/>
    </>
}
